<template>
  <div>
       <section id="popular-courses" class="events">
          <div class="container" data-aos="fade-up">

            <div class="section-title mt-3 mb-3">
              <p class="text-center">{{$t("consent-of-research-leader-main-applicant")}}</p>
            </div>
            <!-- <p class="text-left">{{$t("consent-intro-text")}}</p> -->

            <div class="card contact events" data-aos="zoom-in" data-aos-delay="100">
               <div class="row">
                <div class="card">
                  <div class="card-img">
                    <img src="/assets/img/banner1.png" alt="...">
                  </div>
                  <div class="card-body">
                    <div class="col-lg-12 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                      <div class="icon-boxes d-flex flex-column justify-content-center">
                        <p>{{$t("consent-intro-text")}}</p>
                        <validation-observer ref="verifyForm">
                          <form class="register-form" @submit.prevent="send">
                            <div class="row">
                                <h4 class="my-2">{{$t("scientific-integrity")}}</h4>
                                <ul>
                                  <ol><i class="bi bi-check-circle"></i> {{$t("scientific-integrity-list-1")}}</ol><br/>
                                  <ol><i class="bi bi-check-circle"></i> {{$t("scientific-integrity-list-2")}}</ol><br/>
                                  <ol><i class="bi bi-check-circle"></i> {{$t("scientific-integrity-list-3")}}</ol><br/>
                                  <ol><i class="bi bi-check-circle"></i> {{$t("scientific-integrity-list-4")}}</ol>
                                </ul> 
                                <!-- <div class="row my-3">
                                  <div class="form-group">
                                      <validation-provider rules="required" name="scientific integrity" #default="{ errors }" vid="scientific-integrity">
                                          <div class="col-sm-1">
                                            <input type="checkbox" />
                                          </div> 
                                          <div class="row">
                                              <div class="col-sm-12">
                                                  <small class="text-danger">{{errors[0] }}</small>
                                              </div>
                                          </div>
                                      </validation-provider>
                                  </div>
                                  <div class="col-sm-11">
                                      <label for="email"><b>{{$t("security-integrity-check")}}</b></label>
                                  </div>
                                </div>      -->
                            </div>

                            <div class="row">
                                <h4 class="my-2">{{$t("collegiality")}}</h4>
                                <ul>
                                  <ol><i class="bi bi-check-circle"></i> {{$t("collegiality-list-1")}}</ol><br/>
                                  <ol><i class="bi bi-check-circle"></i> {{$t("collegiality-list-2")}}</ol><br/>
                                  <ol><i class="bi bi-check-circle"></i> {{$t("collegiality-list-3")}}</ol>
                                </ul> 
                                <!-- <div class="row my-3 form-group">
                                  
                                      <validation-provider rules="required" name="collegiality" #default="{ errors }" vid="collegiality">
                                        <div class="col-sm-1 form-group">
                                          <input type="checkbox" />
                                        </div>
                                        <div class="col-sm-11 form-group">
                                      <label for="email"><b>{{$t("collegiality-check")}}</b></label>
                                  </div>
                                          <div class="row">
                                              <div class="col-md-12">
                                                  <small class="text-danger">{{errors[0] }}</small>
                                              </div>
                                          </div>
                                      </validation-provider>
                                </div> -->
                                  
                            </div>

                            <div class="row">
                                <h4 class="my-2">{{$t("protection-of-human-subjects")}}</h4>
                                <p>{{$t("protection-of-human-subjects-intro")}}</p>
                                <ul>
                                  <ol><i class="bi bi-check-circle"></i> {{$t("protection-of-human-subjects-list-1")}}</ol>
                                  <ol><i class="bi bi-check-circle"></i> {{$t("protection-of-human-subjects-list-2")}}</ol>
                                  <ol><i class="bi bi-check-circle"></i> {{$t("protection-of-human-subjects-list-3")}}</ol>
                                  <ol><i class="bi bi-check-circle"></i> {{$t("protection-of-human-subjects-list-4")}}</ol>
                                </ul> 
                                <!-- <div class="row my-3">
                                  <div class="col-sm-1 form-group">
                                      <validation-provider rules="required" name="email" #default="{ errors }" vid="email">
                                          <input type="checkbox" id="email" v-model="form.email" />
                                          <div class="row">
                                              <div class="col-md-12">
                                                  <small class="text-danger">{{errors[0] }}</small>
                                              </div>
                                          </div>
                                      </validation-provider>
                                  </div>
                                  <div class="col-sm-11 form-group">
                                      <label for="email"><b>{{$t("protection-of-human-subjects-check")}}</b></label>
                                  </div>
                                </div>      -->
                            </div>


                             <div class="row">
                                <h4 class="my-2">{{$t("protection-care-of-research-animals")}}</h4>
                                <p>{{$t("protection-care-of-research-animals-intro")}}</p>
                                <!-- <div class="row my-3">
                                  <div class="col-sm-1 form-group">
                                      <validation-provider rules="required" name="email" #default="{ errors }" vid="email">
                                          <input type="checkbox" id="email" v-model="form.email" />
                                          <div class="row">
                                              <div class="col-md-12">
                                                  <small class="text-danger">{{errors[0] }}</small>
                                              </div>
                                          </div>
                                      </validation-provider>
                                  </div>
                                  <div class="col-sm-11 form-group">
                                      <label for="email"><b>{{$t("protection-care-of-research-animals-check")}}</b></label>
                                  </div>
                              </div>      -->
                            </div>

                            <div class="row">
                              <h4 class="my-2">{{$t("integrity-in-relation-to-institution")}}</h4>
                              <p>{{$t("integrity-in-relation-to-institution-intro")}}</p>
                              <ul>
                                <ol><i class="bi bi-check-circle"></i> {{$t("integrity-in-relation-to-institution-list-1")}}</ol><br/>
                                <ol><i class="bi bi-check-circle"></i> {{$t("integrity-in-relation-to-institution-list-2")}}</ol><br/>
                                <ol><i class="bi bi-check-circle"></i> {{$t("integrity-in-relation-to-institution-list-3")}}</ol>
                              </ul> 
                              <!-- <div class="row my-3">
                                <div class="col-sm-1 form-group">
                                    <validation-provider rules="required" name="email" #default="{ errors }" vid="email">
                                        <input type="checkbox" id="email" v-model="form.email" />
                                        <div class="row">
                                            <div class="col-md-12">
                                                <small class="text-danger">{{errors[0] }}</small>
                                            </div>
                                        </div>
                                    </validation-provider>
                                </div>
                                <div class="col-sm-11 form-group">
                                    <label for="email"><b>{{$t("integrity-in-relation-to-institution-check")}}</b></label>
                                </div>
                              </div>      -->
                            </div>

                            <div class="row">
                              <h4 class="my-2">{{$t("social-responsibility")}}</h4>
                              <p>{{$t("social-responsibility-intro")}}</p>
                              <ul>
                                <ol><i class="bi bi-check-circle"></i> {{$t("social-responsibility-list-1")}}</ol><br/>
                                <ol><i class="bi bi-check-circle"></i> {{$t("social-responsibility-list-2")}}</ol>
                              </ul> 
                              <!-- <div class="row my-3">
                                <div class="col-sm-12 form-group">
                                    <validation-provider rules="required" name="email" #default="{ errors }" vid="email">
                                        <input type="checkbox" id="email" v-model="form.email" />
                                        <div class="row">
                                            <div class="col-md-12">
                                                <small class="text-danger">{{errors[0] }}</small>
                                            </div>
                                        </div>
                                    </validation-provider>
                                </div>
                                <div class="col-sm-11 form-group">
                                    <label for="email"><b>{{$t("social-responsibility-check")}}</b></label>
                                </div>
                                
                              </div>      -->
                            </div>
                            <p class="my-3">{{$t("check-text")}}</p>
                            
                            <div class="form-row row my-3">
                              <div class="col-lg-2 form-group">
                                  <label for="name">{{$t("name")}}</label>
                              </div>
                              <div class="col-lg-10 form-group">
                                <validation-provider rules="required" name="name" #default="{ errors }" vid="name">
                                  <input type="text" class="form-control" :class="errors.length > 0 ? 'is-invalid':null" id="name" v-model="form.name" :placeholder="$t('enter-your-name')" :aria-label="$t('enter-your-name')" >
                                  <div class="row">
                                      <div class="col-md-12">
                                          <small class="text-danger">{{errors[0] }}</small>
                                      </div>
                                  </div>
                                </validation-provider>
                              </div>
                            </div>
                            <div class="d-flex justify-content-center">
                              <button type="submit">{{$t("submit")}}</button>
                            </div>
                          </form>
                        </validation-observer>
                      </div>
                    </div>
                  </div>
                </div>
               </div>
            </div>
          </div>
        </section>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '../utils/validations/validations'
export default {
  components : {ValidationProvider, ValidationObserver},
  data(){
    return {
      form : {},
      required
    }
  },
  methods : {
    send(){
      this.$refs.verifyForm.validate().then(success => {
        if(success){
           const loading = this.$vs.loading({
            color : "#FF1234",
            type : "circles",
            text : this.$t("processing-please-wait")
          });
          this.$http.post("/do-consent", this.form)
          .then(response => {
            console.log(response);
            loading.close();
            this.$router.push({name : "Application"});
          }).catch(e => {
            console.log(e)
            loading.close();
          })
        }
        else {
          console.log("sbfhsgf")
        }
      }).catch(e => {
        console.log(e)
      })
    }
  },
  mounted(){
        this.$http.get("/get-consent")
        .then(response => {
            if(response.data.success){
                this.$router.push({name : "Application"});
            }
        }).catch(e => {
            console.log(e)
        })
    }
}
</script>

<style>

</style>